//
// _buttons.scss
//

button,
a {
    outline: none !important;
}

// Rounded Buttons
.btn-rounded {
    border-radius: 30px;
}

.btn {
    &:focus {
        box-shadow: $btn-focus-box-shadow;
    }
}

// example 1

.btn-group-example {
    .btn {
        position: relative;

        &:first-child {
            &::before {
                display: none;
            }
        }

        &:before {
            content: "OR";
            position: absolute;
            font-size: 10px;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border-radius: 50%;
            background-color: var(--#{$prefix}secondary-color);
            color: var(--#{$prefix}secondary-bg);
            border: 1px solid var(--#{$prefix}secondary-bg);
            left: -12px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }
}

// Example 2

.btn-label {
    position: relative;
    padding-left: 44px;

    .label-icon {
        position: absolute;
        width: 32px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba($white, 0.1);
        border-right: 1px solid rgba($white, 0.1);
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.btn-light {
        .label-icon {
            background-color: rgba($dark, 0.1);
            border-right: 1px solid rgba($dark, 0.2);
        }
    }
}


.btn-group-vertical {
    label {
        margin-bottom: 0px;
    }
}


//
// Soft Buttons
//

@mixin button-variant-soft($bg) {
    color: $bg;
    background-color: rgba($bg, 0.1);
    border-color: transparent;
    transition: all 0.5s ease;

    &:hover,
    &:focus,
    &:active {
        color: $white;
        background-color: $bg;
        border-color: transparent;
    }
}

@each $color, $value in $theme-colors {
    .btn-soft-#{$color} {
        @include button-variant-soft($value);
    }
}

.like-btn {
    &.active {
        .bx-heart {
            color: $danger;

            &:before {
                content: "\ec8f";
            }
        }
    }
}


.google-btn {
    background-color: white;
    color: black;

    &:hover {
        background-color: #ededed;
        color: black;
    }
}

.google-icon {
    background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
}

.fb-btn {
    background-color: #03318e;
    color: white;
    font-size: 11px;

    &:hover {
        background-color: #03225f;
        color: white;
    }
}

.fb-icon {
    font-size: 18px
}

.singpass-button {
    font-weight: bold;
    background-color: #F4333D;
    border-color: #cc0000;
    border-radius: 5px;
    padding: 10px 20px;
    color: white;
    transition: background-color 0.3s ease;
}

.singpass-button:hover {
    background-color: #b30000;
    border-color: #b30000;
}
.myinfo{
    // width: 5px;
    // height: 5px;
    background: url("../../../images/16\ x\ 16.svg") left no-repeat content-box;
    border: 1px solid #dadce0;
    border-radius: 4px;
}