.background-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    background: rgb(0, 0, 0, 0.42);
    justify-content: center;
    align-items: center;
}

.overlay-content {
    padding: 20px;
    border-radius: 5px;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.dot {
    width: 12px;
    height: 12px;
    margin: 0 3px;
    border-radius: 70%;
    animation: blinkAnimation 10s;
    background-color: black;
}

@keyframes blinkAnimation {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.dot:nth-child(1) {
    animation-delay: 0.25s;
}

.dot:nth-child(2) {
    animation-delay: 0.5s;
}

.dot:nth-child(3) {
    animation-delay: 0.75s;
}

.loading-text {
    text-align: center;
    margin-left: 5px;
}
