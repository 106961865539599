.required-asterisk {
    color: red;
    margin-left: 4px;
    font-size: 0.8rem;
  }
  .service-title{
    font-size: 1.21875rem;
    color: var(--bs-heading-color);
    font-weight: 500;
    line-height: 1.2;
    margin-top: 0;
  }
  .sub-title{
    font-size: 1.0rem;
    color: var(--bs-heading-color);
    font-weight: 500;
    line-height: 1.2;
    margin-top: 0;
  }
  
  .background-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }